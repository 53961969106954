.app {
  overflow: hidden;
  position: relative;
}
.clipped {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
img {
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.3125rem;
  margin-top: 1rem;
  text-align: center;
}
h5 {
  font-weight: 200;
}
a {
  color: #ff337a;
  text-decoration: none;
}

.page {
  margin: auto;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  color: #e9f3e8;
  /* transition: background-color 3s; */
}
.background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: none;
  object-position: top;
  background-position: top;
  background-repeat: no-repeat;
}
.section {
  padding: 2rem;
}
.content {
  padding: 1rem;
}

.avatar {
  display: block;
  margin: auto;
  margin-top: 1.5rem;
  height: 200px;
  width: 200px;
}
.avatar img {
  display: block;
  height: auto;
  margin: auto;
  max-width: 100%;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
.about .image-container {
  margin: auto;
  max-width: 500px;
}
@media (max-width: 576px) {
  .section {
    padding: 0rem;
  }
}
@media (max-width: 767px) {
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    max-width: 800px;
  }
}

/* Desktop Styles */
@media (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
}
