.nextPageBtn {
  position: fixed;
  left: 50%;
  bottom: 1.5rem;
  transform: translate(-50%, 0);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.nextPageBtn svg {
  font-size: 1.375rem;
  filter: drop-shadow(1px 1px 2px black);
  transition: 0.3s;
}
.nextPageBtn span {
  /* display: none; */
  text-shadow: 0px 0px 3px black;
  font-size: 1rem;
}
.nextPageBtn:hover svg {
  color: #ff337a;
}
/* .nextPageBtn:hover span {
  display: block;
} */
