/* BoxAnimation */
.itemContainer.flipped .connector {
  left: -2rem;
  transform: scaleX(-1);
}
.connector {
  position: absolute;
  width: 3rem;
  right: -2rem;
  top: 0px;
  z-index: 1;
  pointer-events: none;
}
.projectBorder {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  transform: scaleX(-1);
  z-index: 2;
  pointer-events: none;
  @-moz-document url-prefix() {
    border-radius: 0px;
  }
}
.itemContainer.flipped .projectBorder {
  transform: scaleX(1);
}
.projectBorder rect {
  position: absolute;
  top: 0;
  left: 0;
}
/* end BoxAnimation */
.itemContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  flex-basis: calc(50% - 2rem);
  min-width: 250px;
  border-radius: 5px;
  margin: 0;
  height: 100%;
  margin-bottom: 5rem;
}

.timelineItem {
  position: relative;
  border-radius: 10px;
  background-color: #1f1b32;
}
.itemContainer.flipped {
  margin-top: 5rem;
  margin-bottom: 0;
  align-self: flex-end;
}

.contentContainer {
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: white;
}
.projectTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.projectTitle h5 {
  margin: 0;
}
.projectTitle a {
  text-decoration: underline;
  color: white;
}
.projectTitle a:hover {
  color: #ff337a;
}
.logo {
  background-color: white;
  padding: 3px;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  margin: auto;
}
.skills {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  list-style-position: inside;
  list-style-type: none;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
}
.skills li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills svg {
  font-size: 0.5rem;
  padding-right: 0.375rem;
  align-items: center;
}
@media (max-width: 768px) {
  .itemContainer .connector {
    display: none;
  }
  .itemContainer {
    flex-basis: calc(100% - 2rem);
    margin-top: 5rem;
    margin-bottom: 0;
    z-index: 5;
  }
  .contentContainer {
    padding: 1.5rem;
  }
  .projectBorder rect {
    position: absolute;
    top: 0;
    left: 0;
  }
}
